<template>
	<CreateDialog :dialog="createDialog" v-on:close="createDialog = false">
		<template v-slot:title>
			<span>Create Payment</span>
			<span v-if="payment_barcode" class="orange--text text--darken-4 barcode-font-size">
				#{{ payment_barcode }}</span
			>
		</template>
		<template v-slot:body>
			<v-sheet style="height: calc(100vh - 188px)">
				<v-row>
					<v-col md="12">
						<v-form v-if="createDialog" ref="paymentForm">
							<v-row>
								<v-col md="9">
									<perfect-scrollbar
										:options="{ suppressScrollX: true }"
										class="scroll custom-box-top-inner-shadow"
										style="position: relative"
									>
										<v-row class="px-4">
											<v-col md="3">
												<label for="invoice" class="btx-label mt-4 required">Invoice</label>
											</v-col>
											<v-col md="9">
												<AutoCompleteInput
													hide-details
													:items="invoiceList"
													item-text="barcodeTitle"
													item-value="id"
													:loading="pageLoading"
													id="invoice"
													placeholder="Invoice"
													v-model="payment.invoice"
													@change="invoiceChange"
													:disabled="invoiceInputDisable"
													:rules="[vrules.required(payment.invoice, 'Invoice')]"
													:class="{
														required: !payment.invoice,
													}"
												>
												</AutoCompleteInput>
												<div v-if="selected_invoice" class="mt-2">
													<div>
														<span class="fw-500">Title : </span>
														<span>{{ selected_invoice.title }}</span>
													</div>
													<div>
														<span class="fw-500">Invoice Amount : </span>
														<span>{{ formatMoney(selected_invoice.collected_amount) }} </span>
													</div>
												</div>
											</v-col>
											<v-col md="3">
												<label for="payment_date" class="btx-label mt-4 required">Payment Date</label>
											</v-col>
											<v-col md="9">
												<DatePicker
													:rules="[vrules.required(payment.date, 'Payment Date')]"
													:class="{ required: !payment.date }"
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="payment_date"
													v-model="payment.date"
												></DatePicker>
											</v-col>
											<v-col md="3">
												<label for="reference" class="btx-label mt-4">Reference</label>
											</v-col>
											<v-col md="3">
												<TextInput
													id="reference"
													hide-details
													placeholder="Reference"
													v-model="payment.reference"
													:disabled="pageLoading"
													:loading="pageLoading"
												>
												</TextInput>
											</v-col>

											<v-col md="3" class="text-right">
												<label for="amount" class="btx-label mt-4">Invoice Amount</label>
											</v-col>
											<v-col md="3" class="d-flex align-center">
												<span class="font-level-1 mt-2">{{ formatMoney(payment.amount) }}</span>
											</v-col>
											<v-col md="3">
												<label for="amount_received" class="btx-label mt-4">Amount Received</label>
											</v-col>
											<v-col md="3">
												<PriceInput
													id="amount_received"
													hide-details
													placeholder="Amount Received"
													v-model="payment.amount_received"
													:rules="[vrules.required(payment.amount_received, 'Amount')]"
													:class="{
														required: !payment.amount_received,
													}"
													:disabled="pageLoading"
													@keyup="paymentAmountReceived"
													:loading="pageLoading"
												>
												</PriceInput>
											</v-col>
											<v-col md="3" class="text-right">
												<label for="pending_amount" class="btx-label mt-4">Pending Amount</label>
											</v-col>
											<v-col md="3" class="d-flex align-center">
												<!-- <PriceInput id="pending_amount" hide-details placeholder="Pending Amount"
													v-model="payment.pending" disabled :loading="pageLoading">
												</PriceInput> -->
												<span v-if="selected_invoice?.balance" class="font-level-1 mt-2">{{
													formatMoney(selected_invoice?.balance)
												}}</span>
												<span v-else class="font-level-1 mt-2">{{ formatMoney(0) }}</span>
											</v-col>
											<v-col md="3">
												<label for="balance_amount" class="btx-label mt-4">Balance</label>
											</v-col>
											<v-col md="3" class="d-flex align-center">
												<!-- <PriceInput id="balance_amount" hide-details placeholder="Balance"
													v-model="paymentBalance" disabled :loading="pageLoading">
												</PriceInput> -->
												<span class="font-level-1 mt-2">{{ formatMoney(paymentBalance) }}</span>
											</v-col>
											<div class="d-flex align-items-center">
												<v-col md="3">
													<label for="attachment" class="btx-label mt-4">Attachment</label>
												</v-col>
												<v-col md="9">
													<FileUpload id="attachment" :allowAddMore="false" v-model="payment.attachment">
													</FileUpload>
												</v-col>
											</div>
										</v-row>
									</perfect-scrollbar>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
				</v-row>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$router.go(-1)"> Close </v-btn>
			<v-btn class="white--text" depressed color="blue darken-4" tile @click="updateOrCreate">
				Save
			</v-btn>
		</template>
	</CreateDialog>
</template>
<script>
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import Dialog from "@/view/components/Dialog";
import CreateDialog from "@/view/components/CreateDialog";
import TextInput from "@/view/components/TextInput";
import PriceInput from "@/view/components/PriceInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import FileUpload from "@/view/components/FileUpload";
import { /* getMilestoneByUUID */ GetProject } from "@/core/lib/project.lib";
import { mapGetters } from "vuex";
// import { createInvoice } from "@/core/lib/project.lib";
// import { map } from "lodash";
import {
	/* getPaymentByProject */
	/* getInvoiceByProject */
	createPayment,
	/* deletePayment */
} from "@/core/lib/project.lib";
import { /*  createInvoice, */ getInvoiceDropDown } from "@/core/lib/project.lib";
export default {
	name: "create-invoice",
	title: "create invoice",
	components: {
		// Dialog,
		// ShowValue,
		TextInput,
		PriceInput,
		FileUpload,
		// ShowPrice,
		AutoCompleteInput,
		// Chip,
		CreateDialog,
		DatePicker,
	},

	data() {
		return {
			createDialog: true,
			pageLoading: false,
			project: null,
			selected_invoice: null,
			payment_barcode: null,
			current_invoice_amount: null,
			current_balance_amount: null,
			current_invoice_received_amount: null,
			payment: {
				date: null,
				amount: null,
				reference: null,
				balance: null,
				pending: null,
				amount_received: null,
				invoice: null,
				attachment: null,
			},
			projectUUID: null,
			mileUUID: null,
			// project: null,
			milestone: null,
			invoiceList: [],
		};
	},

	methods: {
		async getProject() {
			try {
				// this.pageLoading = true;
				const project = await GetProject(this.$route.params.uuid);
				this.project = project[0];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				// this.pageLoading = false;
			}
		},
		// async getMilestone() {
		// 	try {
		// 		this.pageLoading = false;
		// 		const milestone = await getMilestoneByUUID(this.$route.query.mileUUID);
		// 		this.milestone = milestone[0];
		// 	} catch (error) {
		// 		this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
		// 	} finally {
		// 		this.pageLoading = false;
		// 	}
		// },
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.paymentForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.paymentForm.validate()) {
				return false;
			}
			const formData = {
				project: _this.project.id,
				date: _this.payment.date,
				amount: _this.payment.amount_received,
				paid_amount: _this.payment.amount_received,
				files: _this.payment.attachment,
				invoice: _this.payment.invoice,
				reference: _this.payment.reference,
			};
			try {
				_this.pageLoading = true;
				await createPayment(formData);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Payment has been created." },
				]);
				_this.payment = {
					date: null,
					reference: null,
					amount_received: null,
					invoice: null,
					attachment: null,
				};
				_this.$router.go(-1);
			} catch (error) {
				if (error.data.message) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error.data.message }]);
				} else {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				}
			} finally {
				_this.pageLoading = false;
			}
		},

		async getInvoiceList() {
			try {
				this.pageLoading = true;
				const { invoices } = await getInvoiceDropDown(this.$route.query?.mileUUID);
				this.invoiceList = invoices;
				if (this.invoiceList.length) {
					this.invoiceList.forEach((ele) => {
						if (ele.title) {
							ele.barcodeTitle = ele.barcode + "-" + ele.title;
						} else {
							ele.barcodeTitle = ele.barcode;
						}
					});
				}
			} catch (error) {
				console.log(error);
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		invoiceChange(value) {
			if (!value) {
				this.selected_invoice = null;
				return;
			}
			this.invoiceList.forEach((element) => {
				if (this.payment.invoice === element.id) {
					this.selected_invoice = element;
					this.payment.amount = element.collected_amount;
				}
			});
		},
		paymentAmountReceived() {
			if (this.payment.amount_received > this.selected_invoice.balance) {
				this.payment.amount_received = this.selected_invoice.balance;
			}
		},
		async generateBarcode() {
			this.genrateBarcode("payment").then((output) => {
				this.payment_barcode = output;
			});
		},
	},

	computed: {
		...mapGetters(["errors"]),
		paymentBalance: {
			get() {
				if (this.selected_invoice?.balance - this.payment.amount_received) {
					return this.selected_invoice?.balance - this.payment.amount_received;
				} else {
					return null;
				}
			},
			// setter
			set(newValue) {
				return newValue;
			},
		},
		invoiceInputDisable() {
			if (this.$route.query.invoiceID || this.pageLoading) {
				return true;
			} else {
				return false;
			}
		},
	},
	async mounted() {
		await this.getInvoiceList();
		await this.getProject();
		await this.generateBarcode();
		// if (this.$route.query?.invoiceID) {
		// 	this.payment.invoice = this.$route.query?.invoiceID
		// }
		if (this.$route.query?.invoiceID) {
			this.payment.invoice = Number(this.$route.query?.invoiceID);
			this.invoiceChange(this.payment.invoice);
		}
	},
};
</script>
